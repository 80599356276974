import {
  FETCH_MYTASK_LISTING_REQUEST,
  FETCH_MYTASK_LISTING_SUCCESS,
  FETCH_MYTASK_LISTING_FALUIRE,
  FETCH_MYTASK_MINUUS_ONE_LISTING_FALUIRE,
  FETCH_SEARCH_M_TASK,
} from './myTaskListingTypes';

const initialState = {
  loading: false,
  myTaskListingData: null,
  myCompletedTaskList: [],
  myTLData: null,
  error: '',
};

const myFilter = (data, searchValue) => { 
  let taskListing=[]  
  data &&
   // eslint-disable-next-line
    data.map((data1) => {
      let task = [];
      let obj = {
        name: data1.name,
        project_id: data1.project_id,
      };
      data1 &&
       // eslint-disable-next-line
        data1.task.map((data2) => {
          if (data2.name?.toLowerCase()?.includes(searchValue)) {
            task.push(data2);
          }
        });
      obj['task'] = task;
      taskListing.push(obj);
      
    });  
   

  return taskListing ;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MYTASK_LISTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MYTASK_MINUUS_ONE_LISTING_FALUIRE:
      return {
        ...state,
        myCompletedTaskList: state.myCompletedTaskList.concat(action.payload),
      };

    case FETCH_MYTASK_LISTING_SUCCESS:
      return {
        ...state,
        myTaskListingData: action.payload,
        myTLData: action.payload,
        error: '',
        loading: false,
      };

    case FETCH_SEARCH_M_TASK:
      return {
        ...state,
        myTaskListingData:{
          ...state.myTaskListingData,
          taskListing:myFilter(state?.myTLData?.taskListing,action?.payload?.toLowerCase())
        } 
      };
    // myTaskListingData:myFilter(state?.myTLData?.taskListing,action?.payload?.toLowerCase())

    case FETCH_MYTASK_LISTING_FALUIRE:
      return {
        ...state,
        loading: false,
        myTaskListingData: null,
        myTLData: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
