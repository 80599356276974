import {
  FETCH_UPDATE_USER_BY_USER_ID_REQUEST,
  FETCH_UPDATE_USER_BY_USER_ID_SUCCESS,
  FETCH_UPDATE_USER_BY_USER_ID_FALUIRE,
} from './updateUserByUserIDTypes'

const initialState = {
  loading: false,
  isUpdateUserSuccess: null,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UPDATE_USER_BY_USER_ID_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_UPDATE_USER_BY_USER_ID_SUCCESS : return {
      ...state,
      isUpdateUserSuccess: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_UPDATE_USER_BY_USER_ID_FALUIRE : return {
      ...state,
      loading: false,
      isUpdateUserSuccess: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
