import {
  FETCH_MY_UPCOMING_TASK_LISTING_REQUEST,
  FETCH_MY_UPCOMING_TASK_LISTING_SUCCESS,
  FETCH_MY_UPCOMING_TASK_LISTING_FALUIRE,
  FETCH_MY_UPCOMING_TASK_MINUS_ONE_LISTING_FALUIRE,
  FETCH_SEARCH_M_U_TASK
} from './myUpcomingTaskListingTypes'

const initialState = {
  loading: false,
  myUpcomingTaskListingData: null,
  myUpcomingCompletedTaskList:[],
  myUTaskList:null,
  error: '',
}

const myFilter = (data, searchValue) => { 
  let taskListing=[]  
  data &&
   // eslint-disable-next-line
    data.map((data1) => {
      let task = [];
      let obj = {
        name: data1.name,
        project_id: data1.project_id,
      };
      data1 &&
       // eslint-disable-next-line
        data1.task.map((data2) => {
          if (data2.name?.toLowerCase()?.includes(searchValue)) {
            task.push(data2);
          }
        });
      obj['task'] = task;
      taskListing.push(obj);
      
    });  
   

  return taskListing ;
};

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_MY_UPCOMING_TASK_LISTING_REQUEST : return {
      ...state,
      loading: true,

    }
    case FETCH_MY_UPCOMING_TASK_MINUS_ONE_LISTING_FALUIRE : return {
      ...state,
      myUpcomingCompletedTaskList: state.myUpcomingCompletedTaskList.concat(action.payload) 

    }
    

    case FETCH_MY_UPCOMING_TASK_LISTING_SUCCESS : return {
      ...state,
      myUpcomingTaskListingData: action.payload,
      myUTaskList:action.payload,
      error: '',
      loading: false,
    }
    case FETCH_SEARCH_M_U_TASK : return {
      ...state,
      myUpcomingTaskListingData:{
        ...state.myUpcomingTaskListingData,
        taskListing:myFilter(state?.myUTaskList?.taskListing,action?.payload?.toLowerCase())
      } 
     // myUpcomingTaskListingData:state?.myUTaskList?.filter((val) => (val?.name?.toLowerCase()?.includes(action?.payload?.toLowerCase())))
 
    }


    case FETCH_MY_UPCOMING_TASK_LISTING_FALUIRE : return {
      ...state,
      loading: false,
      myUpcomingTaskListingData: null,
      myUTaskList:null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
