import {
  FETCH_PUBLISHED_TEMPLATE_NAME_BY_ID_REQUEST,
  FETCH_PUBLISHED_TEMPLATE_NAME_BY_ID_SUCCESS,
  FETCH_PUBLISHED_TEMPLATE_NAME_BY_ID_FALUIRE,
} from './templateNameByIDCompletedTaskTypes'

const initialState = {
  loading: false,
  publishedTemplateNameBYIDList: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_PUBLISHED_TEMPLATE_NAME_BY_ID_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_PUBLISHED_TEMPLATE_NAME_BY_ID_SUCCESS : return {
      ...state,
      publishedTemplateNameBYIDList: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_PUBLISHED_TEMPLATE_NAME_BY_ID_FALUIRE : return {
      ...state,
      loading: false,
      publishedTemplateNameBYIDList: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
