import {
  FETCH_GET_COMPLETED_DEVIATION_REPORTS_REQUEST,
  FETCH_GET_COMPLETED_DEVIATION_REPORTS_SUCCESS,
  FETCH_GET_COMPLETED_DEVIATION_REPORTS_FALUIRE  ,
  SEARCH_COMPLETED_DEVAITION_REPORT
} from './completedDeviationReportsTypes';

const initialState = {
  loading: false,
  complDevReport:null,
  completedDeviationReportList:null,
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_COMPLETED_DEVIATION_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_GET_COMPLETED_DEVIATION_REPORTS_SUCCESS:
      return {
        ...state,
        complDevReport:action.payload,
        completedDeviationReportList: action.payload,
        error: '',
        loading: false,
      };

    case FETCH_GET_COMPLETED_DEVIATION_REPORTS_FALUIRE:
      return {
        ...state,
        loading: false,
        complDevReport:null,
        completedDeviationReportList: null,
        error: action.payload,
      };

      case SEARCH_COMPLETED_DEVAITION_REPORT : return {
        ...state,
        completedDeviationReportList:state?.complDevReport?.filter((val) => (val?.task_closed_by?.toLowerCase()?.includes(action?.payload?.toLowerCase())||val?.task_name?.toLowerCase()?.includes(action?.payload?.toLowerCase())))
   
      }
    default:
      return state;
  }
};

export default reducer;
