import {
  FETCH_RESTORE_ROLES_BY_ID_REQUEST,
  FETCH_RESTORE_ROLES_BY_ID_SUCCESS,
  FETCH_RESTORE_ROLES_BY_ID_FALUIRE,
  
} from './restoreTrashRolesTypes'

const initialState = {
  loading: false,
  restoreRole: null,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RESTORE_ROLES_BY_ID_REQUEST: return {
      ...state,
      loading: true,

    }

    case FETCH_RESTORE_ROLES_BY_ID_SUCCESS: return {
      ...state,
      restoreRole: action.payload,
      error: '',
      loading: false,
    }
  
    case FETCH_RESTORE_ROLES_BY_ID_FALUIRE: return {
      ...state,
      loading: false,
      restoreRole: null,
      error: action.payload,
    }
    default: return state
  }
}

export default reducer
