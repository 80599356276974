import {
  FETCH_LOAD_TRASH_ROLES_REQUEST,
  FETCH_LOAD_TRASH_ROLES_SUCCESS,
  FETCH_LOAD_TRASH_ROLES_FALUIRE,
  SEARCH_DELETED_ROLES
  
} from './loadTrashRolesTypes'

const initialState = {
  loading: false,
  trashRolesList: null,
  allTRoles:null,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOAD_TRASH_ROLES_REQUEST: return {
      ...state,
      loading: true,

    }

    case FETCH_LOAD_TRASH_ROLES_SUCCESS: return {
      ...state,
      trashRolesList: action.payload,
      allTRoles:action.payload,
      error: '',
      loading: false,
    }
  
    case FETCH_LOAD_TRASH_ROLES_FALUIRE: return {
      ...state,
      loading: false,
      trashRolesList: null,
      allTRoles:null,
      error: action.payload,
    }
    case SEARCH_DELETED_ROLES: return {
      ...state,    
      trashRolesList: state?.allTRoles?.filter((data)=> data?.name?.toLowerCase()?.includes(action?.payload?.toLowerCase())) ,
     
    }
    default: return state
  }
}

export default reducer
