import {
  FETCH_LIST_ALL_DOCUMENT_TRASH_LIST_REQUEST,
  FETCH_LIST_ALL_DOCUMENT_TRASH_LIST_SUCCESS,
  FETCH_LIST_ALL_DOCUMENT_TRASH_LIST_FALUIRE,
  FETCH_SEARCH_TDOC
} from './listAllDocumentTrashListTypes'

const initialState = {
  loading: false,
  allTDoc:null,
  allDocumentsTrashList: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_LIST_ALL_DOCUMENT_TRASH_LIST_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_LIST_ALL_DOCUMENT_TRASH_LIST_SUCCESS : return {
      ...state,
      allTDoc:action.payload,
      allDocumentsTrashList: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_LIST_ALL_DOCUMENT_TRASH_LIST_FALUIRE : return {
      ...state,
      loading: false,
      allDocumentsTrashList: null,
      error: action.payload,
    }
    case FETCH_SEARCH_TDOC : return {
      ...state,
      allDocumentsTrashList:state.allTDoc&&state.allTDoc.length>0&&state?.allTDoc?.filter((val) => (val?.DocumentVersion?.name?.toLowerCase()?.includes(action?.payload?.toLowerCase())))
 
    }
    default : return state
  }
}

export default reducer
