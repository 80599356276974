import {
  FETCH_LIST_ALL_PROJECT_TEMPLATE_TASK_FOR_PROJECT_SECTION_REQUEST,
  FETCH_LIST_ALL_PROJECT_TEMPLATE_TASK_FOR_PROJECT_SECTION_SUCCESS,
  FETCH_LIST_ALL_PROJECT_TEMPLATE_TASK_FOR_PROJECT_SECTION_FALUIRE,
  FETCH_LIST_FORMATTED_PROJECT_TEMPLATE_LIST_FOR_PROJECT_SECTION_SUCCESS,
} from './listAllProjectTemplateForProjectSectionTypes';

const initialState = {
  loading: false,
  addProjectTemplateInfoForProjectSection: null,
  formattedTemlateTaskListForProjectSection: {},
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_ALL_PROJECT_TEMPLATE_TASK_FOR_PROJECT_SECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LIST_FORMATTED_PROJECT_TEMPLATE_LIST_FOR_PROJECT_SECTION_SUCCESS:
      return {
        ...state,
        formattedTemlateTaskListForProjectSection: action.payload,
      };

    case FETCH_LIST_ALL_PROJECT_TEMPLATE_TASK_FOR_PROJECT_SECTION_SUCCESS:
      return {
        ...state,
        addProjectTemplateInfoForProjectSection: action.payload,
        error: '',
        loading: false,
      };

    case FETCH_LIST_ALL_PROJECT_TEMPLATE_TASK_FOR_PROJECT_SECTION_FALUIRE:
      return {
        ...state,
        loading: false,
        addProjectTemplateInfoForProjectSection: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
