import {
  FETCH_LOAD_ALL_THERMIT_DATA_REQUEST,
  FETCH_LOAD_ALL_THERMIT_DATA_SUCCESS,
  FETCH_LOAD_ALL_THERMIT_DATA_FALUIRE,
  FETCH_LOAD_ALL_THERMIT_FORMATTED_DATA
} from './loadThermitDataTypes'

const initialState = {
  loading: false,
  allThermitData: null,
  formattedThermitDataList: {},
  error: null,
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_LOAD_ALL_THERMIT_DATA_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_LOAD_ALL_THERMIT_FORMATTED_DATA : return {
      ...state,
      loading: false,
      formattedThermitDataList:action.payload

    }
    

    case FETCH_LOAD_ALL_THERMIT_DATA_SUCCESS : return {
      ...state,
      allThermitData: action.payload,
      error: null,
      loading: false,
    }

    case FETCH_LOAD_ALL_THERMIT_DATA_FALUIRE : return {
      ...state,
      loading: false,
      allThermitData: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
