import {
  FETCH_CHAT_LOGIN_REQUEST,
  FETCH_CHAT_LOGIN_SUCCESS,
  FETCH_CHAT_LOGIN_FALUIRE,
  FETCH_CLEAR_ERROR_MESSAGE_FALUIRE,
  FETCH_SET_LOGGED_IN_USER_PROJECT,
  FETCH_CHAT_LOGOUT,
} from './loginChatTypes';
import { startState } from 'codemirror';

const initialState = {
  loading: false,
  chatUserInfo: null,
  chatUserInfoOtherDetails: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHAT_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CLEAR_ERROR_MESSAGE_FALUIRE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case FETCH_SET_LOGGED_IN_USER_PROJECT:
      return {
        ...startState,
        chatUserInfoOtherDetails: action.payload,
      };
    case FETCH_CHAT_LOGOUT:
      return {
        ...state,
        loading: false,
        error: null,
        chatUserInfo: null,
      };

    case FETCH_CHAT_LOGIN_SUCCESS:
      return {
        ...state,
        chatUserInfo: action.payload,
        error: null,
        loading: false,
      };

    case FETCH_CHAT_LOGIN_FALUIRE:
      return {
        ...state,
        loading: false,
        chatUserInfo: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
