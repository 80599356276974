import {
  FETCH_LOAD_TASK_COUNT_REQUEST,
  FETCH_LOAD_TASK_COUNT_SUCCESS,
  FETCH_LOAD_TASK_COUNT_FALUIRE,
} from './loadTaskCountTypes'

const initialState = {
  loading: false,
  allTaskCount: null,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOAD_TASK_COUNT_REQUEST : return {
      ...state,
      loading: true,
      error: '',

    }

    case FETCH_LOAD_TASK_COUNT_SUCCESS : return {
      ...state,
      allTaskCount: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_LOAD_TASK_COUNT_FALUIRE : return {
      ...state,
      loading: false,
      allTaskCount: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
