import {
  FETCH_LOGIN_BY_TOKEN_REQUEST,
  FETCH_LOGIN_BY_TOKEN_SUCCESS,
  FETCH_LOGIN_BY_TOKEN_FALUIRE,
  FETCH_CLEAR_ERROR_MESSAGE_FALUIRE,
} from './loginBaseUponTokenTypes'

const initialState = {
  loading: false,
  userInfoBasedToken: null,
  error: null,
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_LOGIN_BY_TOKEN_REQUEST : return {
      ...state,
      loading: true,

    }
    case FETCH_CLEAR_ERROR_MESSAGE_FALUIRE : return {
      ...state,
      loading: false,
      error: null,

    }

    case FETCH_LOGIN_BY_TOKEN_SUCCESS : return {
      ...state,
      userInfoBasedToken: action.payload,
      error: null,
      loading: false,
    }

    case FETCH_LOGIN_BY_TOKEN_FALUIRE : return {
      ...state,
      loading: false,
      userInfoBasedToken: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
