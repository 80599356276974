import {
  CLOSE_OPENED_TASK_VIEW  
} from './hideOpenedTaskPopupViewTypes'

const initialState = {
  hideOpenedTask: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_OPENED_TASK_VIEW : return {
      ...state,
      hideOpenedTask: action.payload,

    }
    default : return state
  }
}

export default reducer
