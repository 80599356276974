import {
  FETCH_THERMIT_DEPARTMENT_LIST_BASED_PROJECT_ID_REQUEST,
  FETCH_THERMIT_DEPARTMENT_LIST_BASED_PROJECT_ID_SUCCESS,
  FETCH_THERMIT_DEPARTMENT_LIST_BASED_PROJECT_ID_FALUIRE,
} from './thermitDepartmentBasedUponProjectIDTypes'

const initialState = {
  loading: false,
  thermitDepartmentListBasedOnProjectID: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_THERMIT_DEPARTMENT_LIST_BASED_PROJECT_ID_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_THERMIT_DEPARTMENT_LIST_BASED_PROJECT_ID_SUCCESS : return {
      ...state,
      thermitDepartmentListBasedOnProjectID: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_THERMIT_DEPARTMENT_LIST_BASED_PROJECT_ID_FALUIRE : return {
      ...state,
      loading: false,
      thermitDepartmentListBasedOnProjectID: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
