import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next'
import './App.scss';
import packageJson from '../package.json';

 global.appVersion = packageJson.version;

 const checkVersionMismatch = (letestVersions, currentVersions) => {
  if(letestVersions.length || currentVersions.length){
    if(letestVersions!==currentVersions){
      return true
    }else{
      return false
    }

  }
  };


const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const SuperAdminLayout = React.lazy(() =>
  import('./containers/SuperAdminLayout/SuperAdminLayout')
);

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const WaitngForRedirectPage=React.lazy(() =>
import('./views/WaitngForRedirectPage/WaitngForRedirectPage')
);
const ForgotPassword = React.lazy(() => import('./views/Pages/ForgetPassword'));
const ForgetPasswordOTP = React.lazy(() =>
  import('./views/Pages/ForgetPasswordOTP')
);
const ChangePassword = React.lazy(() => import('./views/Pages/ChangePassword'));

class App extends Component {
  constructor(props) {

          super(props);
    
          this.state = {
    
            isLoading: true,
    
            isLatestVersionAvailable: false,
    
            clearCacheAndReload: () => {
    
              console.log('Clearing cache and hard reloading...')
    
              if (caches) {
      console.log('inside cacheeeeeee')
    
                // deleting saved cache one by one
    
                caches.keys().then(function(names) {
    
                  for (let name of names) caches.delete(name);
    
                });
    
              }
    
              // after deleting cached data hard reload the site
    
              window.location.reload(true);
    
            }
    
          };
    
        } 
    componentDidMount() {
        i18n.changeLanguage(localStorage.getItem('language')?localStorage.getItem('language'):'da')
        fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })  

        .then((response) => response.json())

        .then((meta) => {

          const latestVersion = meta.version;

          const currentVersion = global.appVersion;  

          const shouldForceRefresh = checkVersionMismatch(latestVersion, currentVersion);

          if (shouldForceRefresh) {

              console.log(`Latest version - ${latestVersion}. refresh required.`);
              console.log(`Older version - ${currentVersion}.  refresh required.`);

            this.setState({ loading: false, isLatestVersionAvailable: true });

          } else {

            console.log(`Latest version - ${latestVersion}. No refresh required.`);
            console.log(`Older version - ${currentVersion}. No refresh required.`);

            this.setState({ loading: false, isLatestVersionAvailable: false });

          }

        });

    }  

  render() {
    const { userInfo } = this.props;
    document.title = process.env.REACT_APP_META_TITLE;
     const {isLatestVersionAvailable, clearCacheAndReload} = this.state;     
      if (isLatestVersionAvailable) {   
            clearCacheAndReload()      
          }  
    
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/forgetpasswordotp"
              name="Forgot Password OTP"
              render={(props) => <ForgetPasswordOTP {...props} />}
            />
            <Route
              exact
              path="/forgetpassword"
              name="Forgot Password"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/changepassword"
              name="Change Password"
              render={(props) => <ChangePassword {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
             <Route
              exact
              path="/redirecting/:userToken"
              name="Redirectiong"
              render={(props) => <WaitngForRedirectPage {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) =>
                userInfo && userInfo.is_supper_admin === 0 ? (
                  <DefaultLayout {...props} />
                ) : (
                  <SuperAdminLayout {...props} />
                )
              }
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

//export default App

const mapStateToProps = (state) => ({
  userInfo: state.userInfo.userInfo,
});

export default withTranslation()(connect(mapStateToProps, {})(App));
