import {
  FETCH_LOAD_ALL_TRASH_TEMPLATE_REQUEST,
  FETCH_LOAD_ALL_TRASH_TEMPLATE_SUCCESS,
  FETCH_LOAD_ALL_TRASH_TEMPLATE_FALUIRE,
} from './loadAllTrashTemplateTypes'

const initialState = {
  loading: false,
  allTrashTemplateList: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_LOAD_ALL_TRASH_TEMPLATE_REQUEST: return {
      ...state,
      loading: true,

    }

    case FETCH_LOAD_ALL_TRASH_TEMPLATE_SUCCESS: return {
      ...state,
      allTrashTemplateList: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_LOAD_ALL_TRASH_TEMPLATE_FALUIRE: return {
      ...state,
      loading: false,
      allTrashTemplateList: null,
      error: action.payload,
    }
    default: return state
  }
}

export default reducer
