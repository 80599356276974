import {
  FETCH_GET_COMPLETED_TASK_REPORTS_REQUEST,
  FETCH_GET_COMPLETED_TASK_REPORTS_SUCCESS,
  FETCH_GET_COMPLETED_TASK_REPORTS_FALUIRE,
  SEARCH_COMPLETED_REPORT  
} from './completedTaskReportTypes';

const initialState = {
  loading: false,
  complReport:null,
  completedTaskReportList:null,
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_COMPLETED_TASK_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_GET_COMPLETED_TASK_REPORTS_SUCCESS:
      return {
        ...state,
        complReport:action.payload,
        completedTaskReportList: action.payload,
        error: '',
        loading: false,
      };

    case FETCH_GET_COMPLETED_TASK_REPORTS_FALUIRE:
      return {
        ...state,
        loading: false,
        complReport:null,
        completedTaskReportList: null,
        error: action.payload,
      };

      case SEARCH_COMPLETED_REPORT : return {
        ...state,
        allDocumentsTrashList:state?.allTDoc?.filter((val) => (val?.DocumentVersion?.name?.toLowerCase()?.includes(action?.payload?.toLowerCase())))
   
      }
    default:
      return state;
  }
};

export default reducer;
