import {
  FETCH_LIST_ALL_PROJECT_TEMPLATE_REQUEST,
  FETCH_LIST_ALL_PROJECT_TEMPLATE_SUCCESS,
  FETCH_LIST_ALL_PROJECT_TEMPLATE_FALUIRE,
  FETCH_SEARCH_PTEMPLATE
} from './listAllProjectTemplateTypes'

const initialState = {
  loading: false,
  allPTemplate:null,
  allprojectTemplateList: null,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_ALL_PROJECT_TEMPLATE_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_LIST_ALL_PROJECT_TEMPLATE_SUCCESS : return {
      ...state,
      allprojectTemplateList: action.payload,
      allPTemplate:action.payload,
      error: '',
      loading: false,
    }

    case FETCH_LIST_ALL_PROJECT_TEMPLATE_FALUIRE : return {
      ...state,
      loading: false,
      allprojectTemplateList: null,
      allPTemplate:null,
      error: action.payload,
    }
    case FETCH_SEARCH_PTEMPLATE : return {
      ...state,
      allprojectTemplateList:state?.allPTemplate?.filter((val) => (val?.name?.toLowerCase()?.includes(action?.payload?.toLowerCase())||val?.organization?.toLowerCase()?.includes(action?.payload?.toLowerCase())))
 
    }
    default : return state
  }
}

export default reducer
