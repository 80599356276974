import rootReducer from './rootReducer'
import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import localStorage from 'localStorage'

//here is our initial state
let initState = {
  userInfo: '',
  chatUserInfo: '',
  loggedInUserPermissions: '',
  verifyThermitLoginInformation:'',
  selectedProjectName:[],
  allThermitData:'',
  projectSettingsInformation:'',
  loggedinUserInfo:''

}

//this method is used for storing values into the local storage
function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('userInfo', serializedState)
  } catch (e) {
    console.log(e)
  }
}

//this method is used for the reading of the value form the local storage
function readFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('userInfo')
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch (e) {
    console.log(e)
    return undefined
  }
}

//here we ar reading value from the local storage
const cartStateFromLocalStorage = readFromLocalStorage()

//if there are values into the local store we will store that into our initial state

if (cartStateFromLocalStorage) {
  initState = Object.assign(initState, cartStateFromLocalStorage)
}


const store = createStore(rootReducer, initState, applyMiddleware(thunk))
//here we are subscribing any change into the store and will store that into our local store
store.subscribe(() => {
  let appState = store.getState()
  

  //this method is used for storing value into the local storage
  saveToLocalStorage({
    userInfo: appState.userInfo,
    chatUserInfo: appState.chatUserInfo,
    loggedInUserPermissions: appState.loggedInUserPermissions,
    selectedProjectName:appState.selectedProjectName,
    verifyThermitLoginInformation:appState.verifyThermitLoginInformation,
    allThermitData:appState.allThermitData,
    projectSettingsInformation:appState.projectSettingsInformation,
    loggedinUserInfo:appState.loggedinUserInfo
  })
})
export default store
