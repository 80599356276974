import {
  FETCH_OPEN_TEMPLATE_FOLDER_OPEN,
  FETCH_CLOSE_TEMPLATE_FOLDER_CLOSE,
} from './openTemplateFolderTypes';

const initialState = {
  loading: false,
  isFolderOpen: false,
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OPEN_TEMPLATE_FOLDER_OPEN:
      return {
        ...state,
        isFolderOpen: action.payload,
      };

    case FETCH_CLOSE_TEMPLATE_FOLDER_CLOSE:
      return {
        ...state,
        isFolderOpen: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
