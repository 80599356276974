import {  
  FETCH_SET_SELECTED_ORGANISATION_SUCCESS  
} from './selectedOrganisationDataTypes'

const initialState = {
  loading: false,
  selectedOrganisationData: null,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {


    case FETCH_SET_SELECTED_ORGANISATION_SUCCESS : return {
      ...state,
      selectedOrganisationData: action.payload,
      error: '',
      loading: false,
    }
  
    default : return state
  }
}

export default reducer
