import {
  FETCH_LIST_ALL_DELETED_TEMPLATE_TASK_REQUEST,
  FETCH_LIST_ALL_DELETED_TEMPLATE_TASK_SUCCESS,
  FETCH_LIST_ALL_DELETED_TEMPLATE_TASK_FALUIRE,

  
} from './listAllDeletedTemplateTaskTypes';

const initialState = {
  loading: false,
  allDeletedTemplateTaskList: null,  
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_ALL_DELETED_TEMPLATE_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };  
     

    case FETCH_LIST_ALL_DELETED_TEMPLATE_TASK_SUCCESS:
      return {
        ...state,
        allDeletedTemplateTaskList: action.payload,
        error: '',
        loading: false,
      };

    case FETCH_LIST_ALL_DELETED_TEMPLATE_TASK_FALUIRE:
      return {
        ...state,
        loading: false,
        allDeletedTemplateTaskList: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
