import {
  FETCH_LOAD_ALL_THERMITY_PROJECT_REQUEST,
  FETCH_LOAD_ALL_THERMITY_PROJECT_SUCCESS,
  FETCH_LOAD_ALL_THERMITY_PROJECT_FALUIRE,
  
} from './loadAllThermityProjectTypes'

const initialState = {
  loading: false,
  allThermityProjectListing: null,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOAD_ALL_THERMITY_PROJECT_REQUEST: return {
      ...state,
      loading: true,

    }

    case FETCH_LOAD_ALL_THERMITY_PROJECT_SUCCESS: return {
      ...state,
      allThermityProjectListing: action.payload,
      error: '',
      loading: false,
    }
  
    case FETCH_LOAD_ALL_THERMITY_PROJECT_FALUIRE: return {
      ...state,
      loading: false,
      allThermityProjectListing: null,
      error: action.payload,
    }
    default: return state
  }
}

export default reducer
