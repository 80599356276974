import {
  FETCH_GET_PARTICULAR_USER_INFO_REQUEST,
  FETCH_GET_PARTICULAR_USER_INFO_SUCCESS,
  FETCH_GET_PARTICULAR_USER_INFO_FALUIRE,
} from './getParticularUserInfoTypes'

const initialState = {
  loading: false,
  loggedinUserInfo: null,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_PARTICULAR_USER_INFO_REQUEST: return {
      ...state,
      loading: true,

    }

    case FETCH_GET_PARTICULAR_USER_INFO_SUCCESS: return {
      ...state,
      loggedinUserInfo: action.payload,
      error: '',
      loading: false,
    }
    case FETCH_GET_PARTICULAR_USER_INFO_FALUIRE: return {
      ...state,
      loading: false,
      loggedinUserInfo: null,
      error: action.payload,
    }
    default: return state
  }
}

export default reducer
