import {
  FETCH_PEOPLE_ASSOCIATED_WITH_PROJECT_ID_REQUEST,
  FETCH_PEOPLE_ASSOCIATED_WITH_PROJECT_ID_SUCCESS,
  FETCH_PEOPLE_ASSOCIATED_WITH_PROJECT_ID_FALUIRE,
} from './findPeopleBasedProjectIDAndUserIDTypes'

const initialState = {
  loading: false,
  peopleAssociatedWithOrganization: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_PEOPLE_ASSOCIATED_WITH_PROJECT_ID_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_PEOPLE_ASSOCIATED_WITH_PROJECT_ID_SUCCESS : return {
      ...state,
      peopleAssociatedWithOrganization: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_PEOPLE_ASSOCIATED_WITH_PROJECT_ID_FALUIRE : return {
      ...state,
      loading: false,
      peopleAssociatedWithOrganization: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
