import {
  FETCH_SAVE_THERMIT_LOGIN_REQUEST,
  FETCH_SAVE_THERMIT_LOGIN_SUCCESS,
  FETCH_SAVE_THERMIT_LOGIN_FALUIRE  
} from './saveThermitLoginTypes'

const initialState = {
  loading: false,
  saveThermitLogin: null,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SAVE_THERMIT_LOGIN_REQUEST: return {
      ...state,
      loading: true,

    }

    case FETCH_SAVE_THERMIT_LOGIN_SUCCESS: return {
      ...state,
      saveThermitLogin: action.payload,
      error: '',
      loading: false,
    }
  
    case FETCH_SAVE_THERMIT_LOGIN_FALUIRE: return {
      ...state,
      loading: false,
      saveThermitLogin: null,
      error: action.payload,
    }
    default: return state
  }
}

export default reducer
