import {
  FETCH_LIST_ROLES_REQUEST,
  FETCH_LIST_ROLES_SUCCESS,
  FETCH_LIST_ROLES_FALUIRE,
  SEARCH_ROLE
} from './listRolesTypes'

const initialState = {
  loading: false,
  allRolesF:null,
  listRolesInfo: null,
  error: '',
}

const reducer = (state = initialState, action) => {
  

  switch (action.type) {
    case FETCH_LIST_ROLES_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_LIST_ROLES_SUCCESS : return {
      ...state,
      listRolesInfo: action.payload,
      allRolesF:action.payload,
      error: '',
      loading: false,
    }

    case FETCH_LIST_ROLES_FALUIRE : return {
      ...state,
      loading: false,
      listRolesdInfo: null,
      allRolesF:null,
      error: action.payload,
    }
    case SEARCH_ROLE: return {
      ...state,   
      listRolesInfo:state?.allRolesF?.filter((data)=> data?.name?.toLowerCase()?.includes(action?.payload?.toLowerCase())) ,
    
    }
    default : return state
  }
}

export default reducer
