import {
  FETCH_MY_LATE_TASK_LISTING_REQUEST,
  FETCH_MY_LATE_TASK_LISTING_SUCCESS,
  FETCH_MY_LATE_TASK_LISTING_FALUIRE,
  FETCH_MY_LATE_TASK_MINUUS_ONE_LISTING_FALUIRE,
  FETCH_SEARCH_M_L_TASK
} from './myLateTaskListingTypes'

const initialState = {
  loading: false,
  myLtask:null,
  myLateTaskListingData: null,
  myLateCompletedTaskList:[],
  error: '',
}

const myFilter = (data, searchValue) => { 
  let taskListing=[]  
  data &&
   // eslint-disable-next-line
    data.map((data1) => {
      let task = [];
      let obj = {
        name: data1.name,
        project_id: data1.project_id,
      };
      data1 &&
       // eslint-disable-next-line
        data1.task.map((data2) => {
          if (data2.name?.toLowerCase()?.includes(searchValue)) {
            task.push(data2);
          }
        });
      obj['task'] = task;
      taskListing.push(obj);
      
    });

    
   

  return taskListing ;
};

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_MY_LATE_TASK_LISTING_REQUEST : return {
      ...state,
      loading: true,

    }
    case FETCH_MY_LATE_TASK_MINUUS_ONE_LISTING_FALUIRE : return {
      ...state,
      myLateCompletedTaskList: state.myLateCompletedTaskList.concat(action.payload) 

    }
    

    case FETCH_MY_LATE_TASK_LISTING_SUCCESS : return {
      ...state,
      myLateTaskListingData: action.payload,
      myLtask:action.payload,
      error: '',
      loading: false,
    }

    case FETCH_SEARCH_M_L_TASK : return {
      ...state,
      myLateTaskListingData:{
        ...state.myLateTaskListingData,
        taskListing:myFilter(state?.myLtask?.taskListing,action?.payload?.toLowerCase())
      } 
     // myLateTaskListingData:state?.myLtask?.filter((val) => (val?.name?.toLowerCase()?.includes(action?.payload?.toLowerCase())))
 
    }

    case FETCH_MY_LATE_TASK_LISTING_FALUIRE : return {
      ...state,
      loading: false,
      myLateTaskListingData: null,
      myLtask:null,
      error: action.payload,
    }
    default : return state

  }
}

export default reducer
