import {
  FETCH_DOWNLOAD_COMPLETE_REPORT_REQUEST,
  FETCH_DOWNLOAD_COMPLETE_REPORT_SUCCESS,
  FETCH_DOWNLOAD_COMPLETE_REPORT_FALUIRE  
} from './downloadCompletedReportsTypes';

const initialState = {
  loading: false,
  downloadCompletedDeviationReportList:null,
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOWNLOAD_COMPLETE_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DOWNLOAD_COMPLETE_REPORT_SUCCESS:
      return {
        ...state,
        downloadCompletedDeviationReportList: action.payload,
        error: '',
        loading: false,
      };

    case FETCH_DOWNLOAD_COMPLETE_REPORT_FALUIRE:
      return {
        ...state,
        loading: false,
        downloadCompletedDeviationReportList: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
