import {
  FETCH_CHANGE_PASSWORD_REQUEST,
  FETCH_CHANGE_PASSWORD_SUCCESS,
  FETCH_CHANGE_PASSWORD_FALUIRE,
  FETCH_CLEAR_CHANGE_PASSWORD_FALUIRE
} from './changePasswordTypes'

const initialState = {
  loading: false,
  changePasswordInfo: null,
  error: null,
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_CHANGE_PASSWORD_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_CHANGE_PASSWORD_SUCCESS : return {
      ...state,
      changePasswordInfo: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_CHANGE_PASSWORD_FALUIRE : return {
      ...state,
      loading: false,
      changePasswordInfo: null,
      error: action.payload,
    }

    case FETCH_CLEAR_CHANGE_PASSWORD_FALUIRE : return {
      ...state,
      loading: false,      
      error: null,
    }
    default : return state
  }
}

export default reducer
