import {
  FETCH_ADD_SPECIAL_TASK_REQUEST,
  FETCH_ADD_SPECIAL_TASK_SUCCESS,
  FETCH_ADD_SPECIAL_TASK_FALUIRE,
  FETCH_LIST_FORMATTED_PROJECT_TEMPLATE_LIST_SUCCESS,
} from './addSpecialTaskTypes';

const initialState = {
  loading: false,
  addSpecialTaskInfo: null,
  formattedTemlateTaskList: {},
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADD_SPECIAL_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LIST_FORMATTED_PROJECT_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        formattedTemlateTaskList: action.payload,
      };

    case FETCH_ADD_SPECIAL_TASK_SUCCESS:
      return {
        ...state,
        addSpecialTaskInfo: action.payload,
        error: '',
        loading: false,
      };

    case FETCH_ADD_SPECIAL_TASK_FALUIRE:
      return {
        ...state,
        loading: false,
        addSpecialTaskInfo: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
