import {
  FETCH_ADD_ROLES_REQUEST,
  FETCH_ADD_ROLES_SUCCESS,
  FETCH_ADD_ROLES_FALUIRE,
} from './addRolesTypes'

const initialState = {
  loading: false,
  addRolesInfo: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_ADD_ROLES_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_ADD_ROLES_SUCCESS : return {
      ...state,
      addRolesInfo: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_ADD_ROLES_FALUIRE : return {
      ...state,
      loading: false,
      addRolesInfo: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
