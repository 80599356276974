import {
  FETCH_MULTI_DELETE_TASK_REQUEST,
  FETCH_MULTI_DELETE_TASK_SUCCESS,
  FETCH_MULTI_DELETE_TASK_FALUIRE,
  FETCH_MULTI_DELETE_TASK_MINUS_ONE_SUCCESS
} from './multiDeleteTaskTypes'

const initialState = {
  loading: false,
  multiDeleteTask: null,
  deletedTaskListArray:[],
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_MULTI_DELETE_TASK_REQUEST : return {
      ...state,
      loading: true,

    }
    case FETCH_MULTI_DELETE_TASK_MINUS_ONE_SUCCESS:return{
      ...state,
      deletedTaskListArray: state.deletedTaskListArray.concat(action.payload),
    }

    case FETCH_MULTI_DELETE_TASK_SUCCESS : return {
      ...state,
      multiDeleteTask: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_MULTI_DELETE_TASK_FALUIRE : return {
      ...state,
      loading: false,
      multiDeleteTask: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
