import {
  FETCH_PREVIEW_DOCUMENTS_REQUEST,
  FETCH_PREVIEW_DOCUMENTS_SUCCESS,
  FETCH_PREVIEW_DOCUMENTS_FALUIRE,
} from './previewDocumentsTypes'

const initialState = {
  loading: false,
  previewDocumentInfo: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_PREVIEW_DOCUMENTS_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_PREVIEW_DOCUMENTS_SUCCESS : return {
      ...state,
      previewDocumentInfo: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_PREVIEW_DOCUMENTS_FALUIRE : return {
      ...state,
      loading: false,
      previewDocumentInfo: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
