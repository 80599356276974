import {
  SET_ONLINE,
  SET_OFFLINE
} from './setInternetConnectionMessageTypes'
const initialState = {
  internetConnectionMessage: null  
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case SET_ONLINE : return {
      ...state,
      internetConnectionMessage: null,

    }

    case SET_OFFLINE : return {
      ...state,
      internetConnectionMessage: action.payload,
      
    }
   
    default : return state
  }
}

export default reducer
