import {
  FETCH_LOAD_PROJECT_BY_COMPANY_ID_REQUEST,
  FETCH_LOAD_PROJECT_BY_COMPANY_ID_SUCCESS,
  FETCH_LOAD_PROJECT_BY_COMPANY_ID_FALUIRE,
  FETCH_SEARCH_PROJECT
} from './loadAllProjectsByCompanyIDTypes'

const initialState = {
  loading: false,
  allProject:'',
  allProjectListByCompanyID: null,
  error: '',
}

const reducer = (state = initialState, action) => { 

  switch (action.type) {
    
    case FETCH_LOAD_PROJECT_BY_COMPANY_ID_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_LOAD_PROJECT_BY_COMPANY_ID_SUCCESS : return {
      ...state,
      allProject:action.payload,
      allProjectListByCompanyID: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_LOAD_PROJECT_BY_COMPANY_ID_FALUIRE : return {
      ...state,
      loading: false,
      allProjectListByCompanyID: null,
      allProject:null,
      error: action.payload,
    }
    case FETCH_SEARCH_PROJECT : return {
      ...state,
      allProjectListByCompanyID:state.allProject&&state?.allProject?.filter((val) => (val?.name?.toLowerCase()?.includes(action?.payload?.toLowerCase())||val?.organization?.toLowerCase()?.includes(action?.payload?.toLowerCase())))
 
    }
    default : return state
  }
}

export default reducer
