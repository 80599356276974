import {
  FETCH_UNIT_WITH_DEVIATION_WITHOUT_MIN_MAX_REQUEST,
  FETCH_UNIT_WITH_DEVIATION_WITHOUT_MIN_MAX_SUCCESS,
  FETCH_UNIT_WITH_DEVIATION_WITHOUT_MIN_MAX_FALUIRE,
} from './unitWithDeviationWithNoMinMAxTypes'

const initialState = {
  loading: false,
  unitWithDeviationWithoutMinMax: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_UNIT_WITH_DEVIATION_WITHOUT_MIN_MAX_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_UNIT_WITH_DEVIATION_WITHOUT_MIN_MAX_SUCCESS : return {
      ...state,
      unitWithDeviationWithoutMinMax: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_UNIT_WITH_DEVIATION_WITHOUT_MIN_MAX_FALUIRE : return {
      ...state,
      loading: false,
      unitWithDeviationWithoutMinMax: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
