import {
  FETCH_LOAD_OWNER_BY_OWNER_ID_REQUEST,
  FETCH_LOAD_OWNER_BY_OWNER_ID_SUCCESS,
  FETCH_LOAD_OWNER_BY_OWNER_ID_FALUIRE,
  FETCH_CLEAR_OWNER_BY_OWNER_ID,
} from './loadOwnerByOwnerIDTypes'

const initialState = {
  loading: false,
  particularOwnerInfo: null,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOAD_OWNER_BY_OWNER_ID_REQUEST: return {
      ...state,
      loading: true,

    }

    case FETCH_LOAD_OWNER_BY_OWNER_ID_SUCCESS: return {
      ...state,
      particularOwnerInfo: action.payload,
      error: '',
      loading: false,
    }
    case FETCH_CLEAR_OWNER_BY_OWNER_ID: return {
      ...state,
      particularOwnerInfo: null,
      error: '',
      loading: false,

    }
    case FETCH_LOAD_OWNER_BY_OWNER_ID_FALUIRE: return {
      ...state,
      loading: false,
      particularOwnerInfo: null,
      error: action.payload,
    }
    default: return state
  }
}

export default reducer
