import {
  IS_TEMPLATE_UPDATED 
} from './isTemplateChangedTypes'

const initialState = { 
  isTemplateUpdated: true  
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case IS_TEMPLATE_UPDATED : return {
      ...state,
      isTemplateUpdated: action.payload,

    } 
    default : return state
  }
}

export default reducer
