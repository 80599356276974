import {
    FETCH_LOAD_ALL_THERMIT_DATA_GRID_REQUEST,
    FETCH_LOAD_ALL_THERMIT_DATA_GRID_SUCCESS,
    FETCH_LOAD_ALL_THERMIT_DATA_GRID_FALUIRE,
} from './loadThermitDataWithGridType'

const initialState = {
    loading: false,
    allThermitGridData: null,
    error: null,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case FETCH_LOAD_ALL_THERMIT_DATA_GRID_REQUEST: return {
            ...state,
            loading: true,

        }

        case FETCH_LOAD_ALL_THERMIT_DATA_GRID_SUCCESS: return {
            ...state,
            allThermitGridData: action.payload,
            error: null,
            loading: false,
        }

        case FETCH_LOAD_ALL_THERMIT_DATA_GRID_FALUIRE: return {
            ...state,
            loading: false,
            allThermitGridData: null,
            error: action.payload,
        }
        default: return state
    }
}

export default reducer
