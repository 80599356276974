import {
  FETCH_UPDATE_USER_PROFILE_REQUEST,
  FETCH_UPDATE_USER_PROFILE_SUCCESS,
  FETCH_UPDATE_USER_PROFILE_FALUIRE,
} from './updateUserProfileTypes'

const initialState = {
  loading: false,
  updateUserProfile: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_UPDATE_USER_PROFILE_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_UPDATE_USER_PROFILE_SUCCESS : return {
      ...state,
      updateUserProfile: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_UPDATE_USER_PROFILE_FALUIRE : return {
      ...state,
      loading: false,
      updateUserProfile: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
