import {
  FETCH_LOAD_ALL_DELETED_USER_LISR_REQUEST,
  FETCH_LOAD_ALL_DELETED_USER_LISR_SUCCESS,
  FETCH_LOAD_ALL_DELETED_USER_LISR_FALUIRE,
  SEARCH_DELETED_USERS
} from './loadDeletedPeopleListTypes'

const initialState = {
  loading: false,
  deletedUserList: null,
  allDelPeopleF:null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_LOAD_ALL_DELETED_USER_LISR_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_LOAD_ALL_DELETED_USER_LISR_SUCCESS : return {
      ...state,
      deletedUserList: action.payload,
      allDelPeopleF:action.payload,
      error: '',
      loading: false,
    }

    case FETCH_LOAD_ALL_DELETED_USER_LISR_FALUIRE : return {
      ...state,
      loading: false,
      deletedUserList: null,
      allDelPeopleF:null,
      error: action.payload,
    }

    case SEARCH_DELETED_USERS : return {
      ...state,
     
      deletedUserList: state?.allDelPeopleF?.filter((data)=> data?.name?.toLowerCase()?.includes(action?.payload?.toLowerCase())),     
   
     
    }
    default : return state
  }
}

export default reducer
