import {
  FETCH_LOAD_ALL_USERS_REQUEST,
  FETCH_LOAD_ALL_USERS_SUCCESS,
  FETCH_LOAD_ALL_USERS_FALUIRE,
  SEARCH_USER
} from './loadAllUsersTypes'

const initialState = {
  loading: false,
  allTUSer:null,
  allUsersList: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_LOAD_ALL_USERS_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_LOAD_ALL_USERS_SUCCESS : return {
      ...state,
      allTUSer:action.payload,
      allUsersList: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_LOAD_ALL_USERS_FALUIRE : return {
      ...state,
      loading: false,
      allUsersList: null,
      error: action.payload,
    }

    
    case SEARCH_USER : return {
      ...state,     
      allUsersList: state?.allTUSer?.filter((data)=> data?.userName?.toLowerCase()?.includes(action?.payload?.toLowerCase())|| data?.name?.toLowerCase()?.includes(action?.payload?.toLowerCase())||(
      data.organization&&data.organization[0]&&data?.organization[0]?.name?.toLowerCase()?.includes(action?.payload?.toLowerCase()))),     
    }
    default : return state

    
  }
}

export default reducer
