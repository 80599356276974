import {
  FETCH_RESTORE_DELETED_COMPANIES_REQUEST,
  FETCH_RESTORE_DELETED_COMPANIES_SUCCESS,
  FETCH_RESTORE_DELETED_COMPANIES_FALUIRE,
} from './restoreDeletedCompanyTypes'

const initialState = {
  loading: false,
  restoreDeletedCompany: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_RESTORE_DELETED_COMPANIES_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_RESTORE_DELETED_COMPANIES_SUCCESS : return {
      ...state,
      restoreDeletedCompany: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_RESTORE_DELETED_COMPANIES_FALUIRE : return {
      ...state,
      loading: false,
      restoreDeletedCompany: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
