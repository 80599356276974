// import 'react-app-polyfill/ie9'; // For IE 9-11 support

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './polyfill'
import i18n from './i18n'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import store from './redux/store'
import {Provider} from 'react-redux'
import {I18nextProvider} from 'react-i18next'


ReactDOM.render(<I18nextProvider i18n={i18n}><Provider store={store}>
  <App />
</Provider></I18nextProvider>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register()
