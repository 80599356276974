import {
  FETCH_FORGOT_PASSWORD_REQUEST,
  FETCH_FORGOT_PASSWORD_SUCCESS,
  FETCH_FORGOT_PASSWORD_FALUIRE,
  CLEAR_FORGOT_PASSWORD_ERROR,
  CLEAR_CLEAR_FORGOT_PASSWORD_INFO,
} from './forgotPasswordTypes'

const initialState = {
  loading: false,
  forgotPasswordInfo: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_FORGOT_PASSWORD_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_FORGOT_PASSWORD_SUCCESS : return {
      ...state,
      forgotPasswordInfo: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_FORGOT_PASSWORD_FALUIRE : return {
      ...state,
      loading: false,
      forgotPasswordInfo: null,
      error: action.payload,
    }

    case CLEAR_FORGOT_PASSWORD_ERROR : return {
      ...state,
      loading: false,
      error: null,
    }
    case CLEAR_CLEAR_FORGOT_PASSWORD_INFO : return {
      ...state,
      loading: false,
      error: null,
      forgotPasswordInfo: null,
    }
    default : return state
  }
}

export default reducer
