import {
  FETCH_LIST_ALL_DOCUMENTS_REQUEST,
  FETCH_LIST_ALL_DOCUMENTS_SUCCESS,
  FETCH_LIST_ALL_DOCUMENTS_FALUIRE,
  FETCH_SEARCH_ALLDOC
} from './listAllDocumentsTypes'

const initialState = {
  loading: false,
  allDoc:null,
  allDocumentsInfo: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_LIST_ALL_DOCUMENTS_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_LIST_ALL_DOCUMENTS_SUCCESS : return {
      ...state,
      allDoc:action.payload,
      allDocumentsInfo: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_LIST_ALL_DOCUMENTS_FALUIRE : return {
      ...state,
      loading: false,
      allDocumentsInfo: null,
      allDoc:null,
      error: action.payload,
    }
    case FETCH_SEARCH_ALLDOC : return {
      ...state,
      allDocumentsInfo:state?.allDoc?.filter((val) => (val?.document_version?.name?.toLowerCase()?.includes(action?.payload?.toLowerCase())))
 
    }
    default : return state
  }
}

export default reducer
