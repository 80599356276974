import {
  SHOW_SNACKBAR,
  CLOSE_SNACKBAR,
} from './snackBarMessageTypes'

const initialState = {
  snackBarMessage: null,
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case SHOW_SNACKBAR : return {
      ...state,
      snackBarMessage: action.payload,

    }

    case CLOSE_SNACKBAR : return {
      ...state,
      snackBarMessage: null,
    }

    default : return state
  }
}

export default reducer
