import {
  FETCH_LIST_ALL_UPCOMING_TEMPLATE_TASK_REQUEST,
  FETCH_LIST_ALL_UPCOMING_TEMPLATE_TASK_SUCCESS,
  FETCH_LIST_ALL_UPCOMING_TEMPLATE_TASK_FALUIRE,
  FETCH_LIST_ALL_UPCOMING_TEMPLATE_TASK_MINUS_ONE_FALUIRE,
  FETCH_LIST_ALL_UPCOMING_TEMPLATE_TASK_COUNT_REQUEST,
  SEARCH_ALL_UPCOMING_TASK,
} from './listAllUpcomingTemplateTaskTypes';

const initialState = {
  loading: false,
  allTemplateUpcomingTaskList: null,
  countForTaskHeader: null,
  upcomingTemplateCompletedTaskList: [],
  allUTask: null,
  error: '',
};

const myFilter = (data, searchValue) => {
  let taskListing=[]  
  data &&
   // eslint-disable-next-line
    data.map((data1) => {
      let task = [];
      let obj = {
        name: data1.name,
        order_number: data1.order_number,
        project_task_group_id:data1.project_task_group_id
      };
      data1 &&
       // eslint-disable-next-line
        data1.task.map((data2) => {
          if (data2.name?.toLowerCase()?.includes(searchValue)) {
            task.push(data2);
          }
        });
      obj['task'] = task;
      taskListing.push(obj);
      
    });  
   

  return taskListing ;
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_ALL_UPCOMING_TEMPLATE_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_LIST_ALL_UPCOMING_TEMPLATE_TASK_MINUS_ONE_FALUIRE:
      return {
        ...state,
        upcomingTemplateCompletedTaskList:
          state.upcomingTemplateCompletedTaskList.concat(action.payload),
      };

    case FETCH_LIST_ALL_UPCOMING_TEMPLATE_TASK_COUNT_REQUEST:
      return {
        ...state,
        countForTaskHeader: action.payload,
      };

    case FETCH_LIST_ALL_UPCOMING_TEMPLATE_TASK_SUCCESS:
      return {
        ...state,
        allTemplateUpcomingTaskList: action.payload,
        allUTask: action.payload,
        error: '',
        loading: false,
      };
    case SEARCH_ALL_UPCOMING_TASK:
      return {
        ...state,
        allTemplateUpcomingTaskList: myFilter(state?.allUTask,action.payload?.toLowerCase())
      };

    case FETCH_LIST_ALL_UPCOMING_TEMPLATE_TASK_FALUIRE:
      return {
        ...state,
        loading: false,
        allTemplateUpcomingTaskList: null,
        allUTask: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
