import {
  FETCH_UPDATE_PROJECT_SETTINGS_REQUEST,
  FETCH_UPDATE_PROJECT_SETTINGS_SUCCESS,
  FETCH_UPDATE_PROJECT_SETTINGS_FALUIRE  
} from './updateProjectSettingsTypes'

const initialState = {
  loading: false,
  updateProjectSettings: null,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UPDATE_PROJECT_SETTINGS_REQUEST: return {
      ...state,
      loading: true,

    }

    case FETCH_UPDATE_PROJECT_SETTINGS_SUCCESS: return {
      ...state,
      updateProjectSettings: action.payload,
      error: '',
      loading: false,
    }
  
    case FETCH_UPDATE_PROJECT_SETTINGS_FALUIRE: return {
      ...state,
      loading: false,
      updateProjectSettings: null,
      error: action.payload,
    }
    default: return state
  }
}

export default reducer
