import {
  FETCH_SELECTED_PROJECT_REQUEST,
  FETCH_SELECTED_PROJECT_SUCCESS,
  FETCH_SELECTED_PROJECT_FALUIRE,
} from './selectedProjectNameTypes'

const initialState = {
  loading: false,
  selectedProjectName: [],
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SELECTED_PROJECT_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_SELECTED_PROJECT_SUCCESS : return {
      ...state,
      selectedProjectName: action.payload===null?[]:action.payload,
      error: '',
      loading: false,
    }

    case FETCH_SELECTED_PROJECT_FALUIRE : return {
      ...state,
      loading: false,
      selectedProjectName: [],
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
