import {
  FETCH_SIGNUP_REQUEST,
  FETCH_SIGNUP_SUCCESS,
  FETCH_SIGNUP_FALUIRE,
} from './signupTypes'

const initialState = {
  loading: false,
  isUserSignUp: null,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SIGNUP_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_SIGNUP_SUCCESS : return {
      ...state,
      isUserSignUp: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_SIGNUP_FALUIRE : return {
      ...state,
      loading: false,
      isUserSignUp: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
