import {
  FETCH_UPDATE_OWNER_BY_OWNER_ID_REQUEST,
  FETCH_UPDATE_OWNER_BY_OWNER_ID_SUCCESS,
  FETCH_UPDATE_OWNER_BY_OWNER_ID_FALUIRE,
} from './updateOwnerByOwnerIDTypes'

const initialState = {
  loading: false,
  isUpdateOwnerSuccess: null,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UPDATE_OWNER_BY_OWNER_ID_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_UPDATE_OWNER_BY_OWNER_ID_SUCCESS : return {
      ...state,
      isUpdateOwnerSuccess: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_UPDATE_OWNER_BY_OWNER_ID_FALUIRE : return {
      ...state,
      loading: false,
      isUpdateOwnerSuccess: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
