import {
  FETCH_DRAFT_COMPLETED_TASK_LIST_REQUEST,
  FETCH_DRAFT_COMPLETED_TASK_LIST_SUCCESS,
  FETCH_DRAFT_COMPLETED_TASK_LIST_FALUIRE  
} from './fetchDraftCompletedTaskListTypes';

const initialState = {
  loading: false,
  draftCompletedTaskList: null, 
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DRAFT_COMPLETED_TASK_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
   

    case FETCH_DRAFT_COMPLETED_TASK_LIST_SUCCESS:
      return {
        ...state,
        draftCompletedTaskList: action.payload,
        error: '',
        loading: false,
      };

    case FETCH_DRAFT_COMPLETED_TASK_LIST_FALUIRE:
      return {
        ...state,
        loading: false,
        draftCompletedTaskList: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
