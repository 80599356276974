import {
  FETCH_UPDATE_THERMIT_UNIT_MIN_MAX_REQUEST,
  FETCH_UPDATE_THERMIT_UNIT_MIN_MAX_SUCCESS,
  FETCH_UPDATE_THERMIT_UNIT_MIN_MAX_FALUIRE  
} from './updateThermitUnitMinAndMixValueTypes'

const initialState = {
  loading: false,
  updateThermitUnitMaxAndMin: null,
  error: null,
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_UPDATE_THERMIT_UNIT_MIN_MAX_REQUEST : return {
      ...state,
      loading: true,

    }
    

    case FETCH_UPDATE_THERMIT_UNIT_MIN_MAX_SUCCESS : return {
      ...state,
      updateThermitUnitMaxAndMin: action.payload,
      error: null,
      loading: false,
    }

    case FETCH_UPDATE_THERMIT_UNIT_MIN_MAX_FALUIRE : return {
      ...state,
      loading: false,
      updateThermitUnitMaxAndMin: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
