import {
  FETCH_LOAD_ALL_THERMIT_LOG_REQUEST,
  FETCH_LOAD_ALL_THERMIT_LOG_SUCCESS,
  FETCH_LOAD_ALL_THERMIT_LOG_FALUIRE,
  
} from './loadThermitLogTypes'

const initialState = {
  loading: false,
  allThermitLog: null,
  error: null,
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_LOAD_ALL_THERMIT_LOG_REQUEST : return {
      ...state,
      loading: true,

    }
    

    case FETCH_LOAD_ALL_THERMIT_LOG_SUCCESS : return {
      ...state,
      allThermitLog: action.payload,
      error: null,
      loading: false,
    }

    case FETCH_LOAD_ALL_THERMIT_LOG_FALUIRE : return {
      ...state,
      loading: false,
      allThermitLog: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
