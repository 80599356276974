import {
  FETCH_DELETE_PROJECT_TEMPLATE_TASK_LANE_BY_PROJECT_ID_REQUEST,
  FETCH_DELETE_PROJECT_TEMPLATE_TASK_LANE_BY_PROJECT_ID_SUCCESS,
  FETCH_DELETE_PROJECT_TEMPLATE_TASK_LANE_BY_PROJECT_ID_FALUIRE,
} from './deleteProjectTemplateTaskLaneByProjectIDTypes'

const initialState = {
  loading: false,
  deleteProjectTemplateTaskLaneByPRojectID: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_DELETE_PROJECT_TEMPLATE_TASK_LANE_BY_PROJECT_ID_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_DELETE_PROJECT_TEMPLATE_TASK_LANE_BY_PROJECT_ID_SUCCESS : return {
      ...state,
      deleteProjectTemplateTaskLaneByPRojectID: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_DELETE_PROJECT_TEMPLATE_TASK_LANE_BY_PROJECT_ID_FALUIRE : return {
      ...state,
      loading: false,
      deleteProjectTemplateTaskLaneByPRojectID: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
