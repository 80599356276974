import {
  FETCH_LOAD_ORGANISATION_REQUEST,
  FETCH_LOAD_ORGANISATION_SUCCESS,
  FETCH_LOAD_ORGANISATION_FALUIRE,
  SEARCH_COMPANIES
} from './loadOrganisationsTypes'

const initialState = {
  loading: false,
  allOrganisationList: null,
  allCompanyF:null,
  error: '',
}

const reducer = (state = initialState, action) => {  

  switch (action.type) {
    case FETCH_LOAD_ORGANISATION_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_LOAD_ORGANISATION_SUCCESS : return {
      ...state,
      allOrganisationList: action.payload,
      allCompanyF:action.payload,
      error: '',
      loading: false,
    }

    case FETCH_LOAD_ORGANISATION_FALUIRE : return {
      ...state,
      loading: false,
      allOrganisationList: null,
      allCompanyF:null,
      error: action.payload,
    }
    case SEARCH_COMPANIES: return {
      ...state,    
      allOrganisationList: state?.allCompanyF?.filter((data)=> data?.name?.toLowerCase()?.includes(action?.payload?.toLowerCase())) 
    
    }
    default : return state
  }
}

export default reducer
