import {
  FETCH_THERMIT_DATA_WITHOUT_MAX_MIN_REQUEST,
  FETCH_THERMIT_DATA_WITHOUT_MAX_MIN_SUCCESS,
  FETCH_THERMIT_DATA_WITHOUT_MAX_MIN_FALUIRE,
  FETCH_THERMIT_FORMATTED_UNIT_DATA_WITHOUT_MAX_MIN_DATA
} from './loadThermitDataTypes'

const initialState = {
  loading: false,
  thermitDataWithoutMaxMin: null,
  formattedThermitDataWithoutMaxMin: {},
  error: null,
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_THERMIT_DATA_WITHOUT_MAX_MIN_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_THERMIT_FORMATTED_UNIT_DATA_WITHOUT_MAX_MIN_DATA : return {
      ...state,
      loading: false,
      formattedThermitDataWithoutMaxMin:action.payload

    }
    

    case FETCH_THERMIT_DATA_WITHOUT_MAX_MIN_SUCCESS : return {
      ...state,
      thermitDataWithoutMaxMin: action.payload,
      error: null,
      loading: false,
    }

    case FETCH_THERMIT_DATA_WITHOUT_MAX_MIN_FALUIRE : return {
      ...state,
      loading: false,
      thermitDataWithoutMaxMin: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
