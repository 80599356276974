import {
  FETCH_LIST_DELETED_TEMPLATE_TASK_FOR_PROJECT_PAGE_REQUEST,
  FETCH_LIST_DELETED_TEMPLATE_TASK_FOR_PROJECT_PAGE_SUCCESS,
  FETCH_LIST_DELETED_TEMPLATE_TASK_FOR_PROJECT_PAGE_FALUIRE,
  FETCH_SEARCH_TTEPMLATE
} from './listAllDeletedProjectTemplateTypes'

const initialState = {
  loading: false,
  allTTemplate:null,
  allDeletedProjectTemplateTaskForProjectPage: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_LIST_DELETED_TEMPLATE_TASK_FOR_PROJECT_PAGE_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_LIST_DELETED_TEMPLATE_TASK_FOR_PROJECT_PAGE_SUCCESS : return {
      ...state,
      allTTemplate:action.payload,
      allDeletedProjectTemplateTaskForProjectPage: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_LIST_DELETED_TEMPLATE_TASK_FOR_PROJECT_PAGE_FALUIRE : return {
      ...state,
      loading: false,
      allDeletedProjectTemplateTaskForProjectPage: null,
      allTTemplate:null,
      error: action.payload,
    }

    case FETCH_SEARCH_TTEPMLATE : return {
      ...state,
      allDeletedProjectTemplateTaskForProjectPage:state?.allTTemplate?.filter((val) => (val?.name?.toLowerCase()?.includes(action?.payload?.toLowerCase())))
 
    }
    default : return state
  }
}

export default reducer
