import {
  FETCH_LIST_ALL_TEMPLATE_FODER_REQUEST,
  FETCH_LIST_ALL_TEMPLATE_FODER_SUCCESS,
  FETCH_LIST_ALL_TEMPLATE_FODER_FALUIRE,

  
} from './listAllTemplateFolderTypes';

const initialState = {
  loading: false,
  allTemplateFolderList: null,  
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_ALL_TEMPLATE_FODER_REQUEST:
      return {
        ...state,
        loading: true,
      };  
     

    case FETCH_LIST_ALL_TEMPLATE_FODER_SUCCESS:
      return {
        ...state,
        allTemplateFolderList: action.payload,
        error: '',
        loading: false,
      };

    case FETCH_LIST_ALL_TEMPLATE_FODER_FALUIRE:
      return {
        ...state,
        loading: false,
        allTemplateFolderList: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
