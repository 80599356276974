import {
  FETCH_ADD_PROJECT_TEMPLATE_REQUEST,
  FETCH_ADD_PROJECT_TEMPLATE_SUCCESS,
  FETCH_ADD_PROJECT_TEMPLATE_FALUIRE,
  FETCH_LIST_FORMATTED_PROJECT_TEMPLATE_LIST_SUCCESS,
  CLEAN_ARCHIVE_TYPE,
} from './addProjectTemplateTypes';

const initialState = {
  loading: false,
  addProjectTemplateInfo: null,
  formattedTemlateTaskList: {},
  archiveType: null,
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADD_PROJECT_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        archiveType: action.archiveType
      };
    case FETCH_LIST_FORMATTED_PROJECT_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        formattedTemlateTaskList: action.payload,
        archiveType: action.archiveType,
      };

    case FETCH_ADD_PROJECT_TEMPLATE_SUCCESS:
      return {
        ...state,
        addProjectTemplateInfo: action.payload,
        error: '',
        loading: false,
        archiveType: action.archiveType
      };

    case FETCH_ADD_PROJECT_TEMPLATE_FALUIRE:
      return {
        ...state,
        loading: false,
        addProjectTemplateInfo: null,
        error: action.payload,
        archiveType: action.archiveType
      };
      case CLEAN_ARCHIVE_TYPE:
        return {
          ...state,
          archiveType: action.payload
        };
    default:
      return state;
  }
};

export default reducer;
