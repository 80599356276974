import {
  FETCH_CREATE_USER_REQUEST,
  FETCH_CREATE_USER_SUCCESS,
  FETCH_CREATE_USER_FALUIRE,
} from './createUserTypes'

const initialState = {
  loading: false,
  isCreateUserSuccess: null,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CREATE_USER_REQUEST : return {
      ...state,
      loading: true,
      error: '',

    }

    case FETCH_CREATE_USER_SUCCESS : return {
      ...state,
      isCreateUserSuccess: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_CREATE_USER_FALUIRE : return {
      ...state,
      loading: false,
      isCreateUserSuccess: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
