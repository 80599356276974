import {
  FETCH_DOCUMENT_ASSOCIATED_PROJECT_REQUEST,
  FETCH_DOCUMENT_ASSOCIATED_PROJECT_SUCCESS,
  FETCH_DOCUMENT_ASSOCIATED_PROJECT_FALUIRE,
} from './findAssociativeDocumentProjectTypes'

const initialState = {
  loading: false,
  documentAssociatedProjectList: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_DOCUMENT_ASSOCIATED_PROJECT_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_DOCUMENT_ASSOCIATED_PROJECT_SUCCESS : return {
      ...state,
      documentAssociatedProjectList: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_DOCUMENT_ASSOCIATED_PROJECT_FALUIRE : return {
      ...state,
      loading: false,
      documentAssociatedProjectList: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
