import {
  FETCH_CREATE_DOCUMENT_FOLDER_REQUEST,
  FETCH_CREATE_DOCUMENT_FOLDER_SUCCESS,
  FETCH_CREATE_DOCUMENT_FOLDER_FALUIRE,
} from './createDocumentFolderTypes'

const initialState = {
  loading: false,
  isCreatedDocumentFolderSuccess: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_CREATE_DOCUMENT_FOLDER_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_CREATE_DOCUMENT_FOLDER_SUCCESS : return {
      ...state,
      isCreatedDocumentFolderSuccess: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_CREATE_DOCUMENT_FOLDER_FALUIRE : return {
      ...state,
      loading: false,
      isCreatedDocumentFolderSuccess: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
